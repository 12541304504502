import { useContext } from "react";
import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client/errors";
import { LocalizationContext } from "../localization";
import {
  parseRetrieveAddressGraphQLErrors,
  parseAndLogUnknownErrors,
  parseVerifyUserGraphQLErrors,
} from "v3/helpers/errors";
import { parseSignupGraphQLErrors } from "@pepdirect/v3/helpers/errors";
import { SignupErrorKeys } from "@pepdirect/v3/types";

export interface PortalErrorContextInterface {
  handlePortalErrors: (e: unknown, v2OperationName?: string) => string | null;
}

/**
 * shared in both v2 and v3
 */
export function usePortalErrorProvider(): {
  portalErrorContextValue: PortalErrorContextInterface;
} {
  const { localization } = useContext(LocalizationContext);

  /**
   * NOTE: because v2 isn't fully on our error policy yet, the operationName
   * from the message in a graphQLError may not be what we are expecting.
   * For all v2 endpoints using this, pass in the operation name for better
   * logging in Sentry.
   */
  const handlePortalErrors = (e: unknown, v2OperationName?: string) => {
    if (e instanceof ApolloError && e.graphQLErrors.length) {
      const { graphQLErrors } = e;
      const operationName = v2OperationName || graphQLErrors[0].message;

      if (operationName === "retrieveAddress") {
        const { errorMsg: parsedErrorMsg } = parseRetrieveAddressGraphQLErrors(
          e.graphQLErrors as GraphQLError[],
          localization?.checkout?.errors
        );

        return parsedErrorMsg;
      }

      if (operationName === "verifyUser") {
        const { errorKey } = parseVerifyUserGraphQLErrors(
          e.graphQLErrors as GraphQLError[]
        );

        return errorKey;
      }
      if (operationName === "signupUser") {
        const { errorMsg, emailVerificationRequired } =
          parseSignupGraphQLErrors(e.graphQLErrors as GraphQLError[]);
        if (emailVerificationRequired) {
          return SignupErrorKeys.emailVerificationRequired;
        }
        return errorMsg;
      }
      // everything else has no specific handlers defined on the FE
      // so we log them as unknown
      parseAndLogUnknownErrors(e, operationName);
    } else {
      console.warn(e);
    }

    return null;
  };

  const portalErrorContextValue: PortalErrorContextInterface = {
    handlePortalErrors,
  };
  return { portalErrorContextValue };
}
